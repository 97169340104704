
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


/* .makeStyles-back-23 {
    color: white;
    background: red !important;
} */

.makeStyles-transparent-30 {
    color: #FFFFFF;
    box-shadow: none;
    padding-top: unset;
    background-color: #f8f2f22b !important;
}
.MuiTypography-noWrap{
    font-family: 'Poppins', sans-serif;
    text-transform: lowercase;
}